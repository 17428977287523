import {Utils} from "@Utils";
import { RainAnalyticsTrackerClick } from "../interfaces/rain-analytics-tracker";

export class ButtonClickedTracking {
    static  readonly type = Utils.Helpers.Type('[Rain Analytics Tracker] Button Clicked');
    constructor(public payload: RainAnalyticsTrackerClick) { }
}
export class ButtonClickedTrackingSuccess {
    static  readonly type = Utils.Helpers.Type('[Rain Analytics Tracker] Button Clicked http Success');
}
export class ButtonClickedTrackingFailed {
    static  readonly type = Utils.Helpers.Type('[Rain Analytics Tracker] Button Clicked http Failed');
}