import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { RainAnalyticsTrackerClick } from "../interfaces/rain-analytics-tracker";
import { Injectable } from "@angular/core";
import { ButtonClickedTracking, ButtonClickedTrackingFailed, ButtonClickedTrackingSuccess } from "../actions/rain-analytics-tracker.actions";
import { RainAnalyticsTrackerService } from "@services/rain-analytics-tracker.service";
import { tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { CoreState } from "src/app/core/store/state/core.state";
import { AuthState } from "src/app/core/store/state/auth.state";
import { v4 as uuidv4 } from 'uuid';

export interface RainAnalyticsTrackerModel {
    rainAnalyticsTracker: RainAnalyticsTrackerClick[],
    sessionId: string,
    sessionStartTimestamp: string
}
@State<RainAnalyticsTrackerModel>({
    name: 'rainAnalyticsTracker',
    defaults: {
        rainAnalyticsTracker: [],
        sessionId: uuidv4(),
        sessionStartTimestamp: new Date().toUTCString()
    }

})
@Injectable({
    providedIn: 'root'
})
export class RainAnalyticsTrackerState {
    constructor(
        private store: Store,
        private rainAnalyticsService: RainAnalyticsTrackerService
    ) { 
    }

    @Action(ButtonClickedTracking)
    buttonClickedTracking(ctx: StateContext<RainAnalyticsTrackerModel>, action: ButtonClickedTracking) {
        const state = ctx.getState();
        const btnClick = action.payload;
        const userId = this.store.selectSnapshot(AuthState.getDigitalIdentity)?.id ?? 'n/a';
        const resolution = this.store.selectSnapshot(CoreState.activeBreakpoint);

        btnClick.userId = userId;
        btnClick.timestamp = new Date().toISOString();
        btnClick.pageUrl = document.referrer;
        btnClick.referrerUrl = '';
        btnClick.deviceInfo = resolution + " "+ navigator.userAgent;
        btnClick.sessionId = state.sessionId;
        btnClick.timeOnPage = 0;
        btnClick.scrollDepth = 0;
        btnClick.clickCoordinates = 'n/a';
        btnClick.source = 'rain.co.za';
        btnClick.sessionStartTimestamp = state.sessionStartTimestamp
        
        console.log('btnClick:', btnClick);
        let previousEvents = [...state.rainAnalyticsTracker];
        
       return this.rainAnalyticsService.pushButtonClick(btnClick)
        .pipe(
            tap({
                next: (res) => {
                    previousEvents.push(res);
                    ctx.patchState({
                        rainAnalyticsTracker: previousEvents
                    })
                    ctx.dispatch(new ButtonClickedTrackingSuccess());
                   
                },
                error: (err) => {
                    ctx.dispatch(new ButtonClickedTrackingFailed());
                }
            })

        )
    }

    @Action(ButtonClickedTrackingSuccess)
    buttonClickedTrackingSuccess() {

    }

    @Action(ButtonClickedTrackingFailed)
    buttonClickedTrackingFailed() {
    
    }

}